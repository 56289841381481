import React from "react"
import styled from "@emotion/styled"

import {
  Body,
  BodySmallBold,
  breakpoints,
  colours,
  column,
  ExpandableCard,
  GridRow,
  Headline2,
  horizontal,
  TextLink,
  vertical,
} from "design-kit"

const BodyWithSmallMargin = styled(Body)`
  margin-bottom: ${vertical.xs};
`

const Ul = styled.ul`
  color: ${colours.offBlack};
  list-style: unset;
  padding-left: ${horizontal.s};
`

const Content1: React.FC = () => (
  <React.Fragment>
    <BodyWithSmallMargin>
      We’re still a whole of market broker. That means we’ll search through
      thousands of deals from more than 90 lenders to find your perfect deal.
      Our advice will always be unbiased and free.
    </BodyWithSmallMargin>
    <BodyWithSmallMargin>
      The difference is that from now, if our technology suggests a Habito
      mortgage is the right choice for you, we’ll show you that option.
    </BodyWithSmallMargin>
    <BodyWithSmallMargin>
      Our aim is to give people more, and better, choices. All done the Habito
      way – fast, efficient and totally transparent.
    </BodyWithSmallMargin>
  </React.Fragment>
)

const Content3: React.FC = () => (
  <Ul>
    <li>
      <BodyWithSmallMargin>
        We’ve formally separated our lending from our brokerage. And we’re fully
        licensed by the FCA to do both mortgage broking and lending.
      </BodyWithSmallMargin>
    </li>
    <li>
      <BodyWithSmallMargin>
        Our algorithms only look at the specific features of mortgages to see
        which works best for you. They don’t take the providers into account.
      </BodyWithSmallMargin>
    </li>
    <li>
      <BodyWithSmallMargin>
        Our mortgage experts get the same commission, no matter what mortgage
        they recommend.
      </BodyWithSmallMargin>
    </li>
    <li>
      <BodyWithSmallMargin>
        Uniquely in the industry, when we recommend you a mortgage, we’ll show
        you our runners-up, ordered by true cost–the real cost of the mortgage
        over the deal period, with all fees and incentives included (a better
        measure of overall cost than interest rates alone). We’ll explain why we
        didn’t choose them–so you can see and challenge our thinking.
      </BodyWithSmallMargin>
    </li>
  </Ul>
)

const content = [
  {
    title:
      "Habito is still a whole of market broker, here to get you the best deal from thousands of mortgages",
    content: <Content1 />,
    id: "habito-market-broker",
  },
  {
    title: "We’re supported by investors",
    content: (
      <BodyWithSmallMargin>
        We’re launching with the support of a leading financial institution,
        regulated by the FCA. Later on, we plan to work with lots of different
        investors to make sure we get our customers good deals and innovative
        mortgages. We have a lending licence from the FCA, and are fully
        regulated.
      </BodyWithSmallMargin>
    ),
    id: "supported-by-investors",
  },
  {
    title: "Lending and broking together: how we keep it fair",
    content: <Content3 />,
    id: "lending-broking-together",
  },
]

const ContentContainer = styled.div`
  ${column({
    widthMobile: 4,
    widthTablet: 6,
    widthDesktop: 8,
    offsetDesktop: 2,
  })};
  color: ${colours.offBlack};
  padding-top: ${vertical.xl};
  margin-bottom: ${vertical.l};

  ${breakpoints.tablet`
    padding-top: ${vertical.xxl};
  `}
`

const Title = styled(Headline2)`
  margin-bottom: ${vertical.l};
`

const BodyWithBigMargin = styled(Body)`
  margin-bottom: ${vertical.m};
`

const RepossessionWarning = styled(BodySmallBold)`
  ${column({
    widthMobile: 4,
    widthTablet: 6,
    widthDesktop: 8,
    offsetDesktop: 2,
  })};
  color: ${colours.offBlack};
  margin-bottom: ${vertical.xl};
`

const RedesigningMortgages: React.FC = () => {
  const [openItem, setOpenItem] = React.useState<string | null>(null)

  return (
    <React.Fragment>
      <GridRow>
        <ContentContainer>
          <Title>Redesigning mortgages: how we’re doing it</Title>

          <BodyWithBigMargin>
            Mortgages are a problem. We set out to solve them. When Habito
            launched in 2015, we started with the process – giving customers the
            best possible experience of getting a mortgage.
          </BodyWithBigMargin>

          <BodyWithBigMargin>
            In 2018, we got our lending licence from the FCA, and set out to
            solve the mortgages themselves, not just the experience around them.
          </BodyWithBigMargin>

          <BodyWithBigMargin>
            This is something no-one has ever done before. To do it, we had to
            start from scratch.
          </BodyWithBigMargin>

          <BodyWithBigMargin>
            Our engineers, product people, designers and researchers have spent
            the last 18 months working on a brand new lending platform, designed
            entirely around the customer.
          </BodyWithBigMargin>

          <BodyWithBigMargin>
            We’ve automated more parts of the process than ever before. Our
            automatic fraud checks keep things safe for customers. Integrating
            our system with solicitors keeps everything transparent and reduces
            the amount of documents we need. Even when we do manual valuations,
            they’re then instructed automatically. And we’ve created an Instant
            Decision, powered by technology, that’s stronger than almost all
            lenders’ decisions in principle.
          </BodyWithBigMargin>

          <BodyWithBigMargin>
            That minimises human error, makes the process much faster, and gives
            customers more certainty.
          </BodyWithBigMargin>

          <BodyWithBigMargin>
            We’re launching with a range of{" "}
            <TextLink text="buy-to-let mortgages" href="/our-btl-range" />, but
            it’s only the beginning. This is how all mortgages should be from
            now on. Designed to benefit customers, not the industry.
          </BodyWithBigMargin>

          <ExpandableCard
            items={content.map(item => ({
              ...item,
              isExpanded: openItem === item.id,
            }))}
            onChange={id => {
              setOpenItem(openItem === id ? null : id)
            }}
          />
        </ContentContainer>

        <RepossessionWarning>
          Your property may be repossessed if you do not keep up repayments on
          your mortgage.
        </RepossessionWarning>
      </GridRow>
    </React.Fragment>
  )
}

export default RedesigningMortgages
