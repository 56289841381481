import { FunctionComponent } from "react"
import styled from "@emotion/styled"

import {
  GridRow,
  Headline1,
  breakpoints,
  colours,
  column,
  vertical,
} from "design-kit"

import keys from "../../assets/images/keys_wings.png"

const Sky = styled.div`
  background-color: ${colours.action.main};
  padding-top: calc(${vertical.xl} + ${vertical.m});
  padding-bottom: ${vertical.m};
`

const GridInner = styled.div`
  ${column({
    widthMobile: 4,
    widthTablet: 6,
    widthDesktop: 12,
  })};
  color: ${colours.white};
  display: flex;
  flex-direction: column;

  ${breakpoints.desktop`
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
  `}
`

const Keys = styled.img`
  align-self: flex-end;
  width: 120px;
  height: 120px;

  ${breakpoints.desktop`
    align-self: unset;
    width: 240px;
    height: 240px;
  `}
`

const TitleBanner: FunctionComponent = () => {
  return (
    <Sky>
      <GridRow>
        <GridInner>
          <Keys src={keys} alt="" />
          <Headline1>We're no longer just a broker</Headline1>
        </GridInner>
      </GridRow>
    </Sky>
  )
}

export default TitleBanner
