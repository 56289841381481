import React, { FunctionComponent } from "react"
import styled from "@emotion/styled"

import {
  Body,
  TextLink,
  breakpoints,
  colours,
  horizontal,
  vertical,
} from "design-kit"

import sweaty from "./assets/sweaty.jpg"
import allhands from "./assets/allhands.jpg"

const PepitaRowTextFirst = styled.div`
  background-color: ${colours.greyScale.grey25};
  display: flex;
  flex-direction: column;

  ${breakpoints.tablet`
  flex-direction: row;
  align-items: center;
  `}
`

const PepitaRowImageFirst = styled.div`
  background-color: ${colours.greyScale.grey25};
  display: flex;
  flex-direction: column;

  ${breakpoints.tablet`
    flex-direction: row-reverse;
    align-items: center;
  `}
`

const TextHalfOnLeft = styled.div`
  padding: ${vertical.xl} ${horizontal.s};

  ${breakpoints.tablet`
    width: 50%;
    display: flex;
    justify-content: flex-end;
    box-sizing: border-box;
    padding: ${vertical.s} ${horizontal.xl};
  `}

  ${breakpoints.desktop`
    padding: ${vertical.xl} ${horizontal.xxl};
  `}
`

const TextHalfOnRight = styled.div`
  padding: ${vertical.xl} ${horizontal.s};

  ${breakpoints.tablet`
    width: 50%;
    min-width: 50%;
    box-sizing: border-box;
    padding: ${vertical.s} ${horizontal.xl};
  `}

  ${breakpoints.desktop`
    padding: ${vertical.xl} ${horizontal.xxl};
  `}
`

const BodyStyled = styled(Body)`
  max-width: 400px;
  margin-bottom: ${vertical.xs};
`

const ImageHalf = styled.div`
  max-height: 400px;
  overflow: hidden;

  ${breakpoints.tablet`
    width: 50%;
    max-height: 600px;
  `}
`

const Img = styled.img`
  width: 100%;

  ${breakpoints.desktop`
    margin-top: -200px;
  `}
`

const PepitaWithPics: FunctionComponent = () => (
  <React.Fragment>
    <PepitaRowTextFirst>
      <TextHalfOnLeft>
        <div>
          <BodyStyled>
            Mortgages have barely changed in decades. The process is slow,
            complicated and full of broken promises. And too many people are
            excluded.
          </BodyStyled>
          <BodyStyled>We’re changing that.</BodyStyled>
          <BodyStyled>
            Habito has already transformed the way mortgage advice is done,
            letting you get a mortgage all online, and for free. But setting
            people free from the hell of mortgages is about more than making the
            advice better. It’s about transforming the mortgages themselves.
          </BodyStyled>
        </div>
      </TextHalfOnLeft>
      <ImageHalf>
        <Img
          src={sweaty}
          alt="Illustration of a man looking at an abundance of mortgage paperwork, stressed, sweating."
        />
      </ImageHalf>
    </PepitaRowTextFirst>
    <PepitaRowImageFirst>
      <TextHalfOnRight>
        <BodyStyled>
          So Habito is no longer just a broker. We’re creating brand new
          mortgages to solve real world problems. Like needing a huge deposit,
          or being told you’re too old to apply.
        </BodyStyled>
        <BodyStyled>
          From now on, Habito will bring you the best advice, and the best
          mortgages, all on one platform.
        </BodyStyled>
        <BodyStyled>
          We’re launching with an innovative range of{" "}
          <TextLink text="buy-to-let mortgages" href="/our-btl-range" />. But
          it’s only the beginning.
        </BodyStyled>
      </TextHalfOnRight>
      <ImageHalf>
        <Img
          src={allhands}
          alt="Habito's CEO Dan holding an all-hands meeting."
        />
      </ImageHalf>
    </PepitaRowImageFirst>
  </React.Fragment>
)

export default PepitaWithPics
